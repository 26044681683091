<template>
  <div>
    <el-dialog
      :title="title"
      :visible="payShow"
      width="900px"
      :before-close="close"
      @open="loading"
    >
      <Divider :title="'复核确认'" style="margin: 10px 0"></Divider>
      <el-row>
        <el-col :span="11">
          <div>手机号：{{ phone }}</div>
          <div style="display: flex; margin: 10px 0; align-items: center">
            验证码：
            <el-input size="small" v-model="sms" style="width: 100px; margin: 10px"></el-input>

            <el-button
              type="primary"
              size="mini"
              plain
              title="验证码将发送至复核人手机"
              style="border-left: none"
              @click="sendSmsCode"
              v-if="!isSend"
            >获取验证码</el-button>
            <el-button
              type="info"
              size="mini"
              plain
              title="验证码将发送至复核人手机"
              style="border-left: none"
              ss
              v-if="isSend"
            >{{ seconds }}S</el-button>
          </div>
        </el-col>
        <el-col :span="11" :push="1">
          备注:
          <el-input type="textarea" v-model="remark" id></el-input>
        </el-col>
      </el-row>
      <Divider :title="`${title.substring(0, 2)}信息`" style="margin: 10px 0"></Divider>
      <el-row>
        <el-col :span="11">
          付款账户：{{ loanReviewDetail.payAccountName }}-{{
            loanReviewDetail.payAccount
          }}
        </el-col>
        <el-col :span="11" :push="1">
          收款账户：{{ loanReviewDetail.payeeAccountName }}-{{
            loanReviewDetail.payeeAccount
          }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">{{ title.substring(0, 2) }}金额：￥{{ loanReviewDetail.money | money }}</el-col>
        <el-col :span="11" :push="1">发起时间：{{ loanReviewDetail.createTime | dateVal }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="11">备注：{{ loanReviewDetail.remark }}</el-col>
      </el-row>
      <Divider :title="'审批记录'" style="margin: 10px 0">
        <span slot="right" style="color: grey">
          <span v-if="loanReviewDetail.state === '0'" style="color: red">未通过</span>
          <span v-if="loanReviewDetail.state === '2'" style="color: red">已完成</span>
          <span v-if="loanReviewDetail.state === '3'" style="color: red">作废</span>
          <span v-if="loanReviewDetail.state === '1'" style="color: blue">待复核</span>
          <span v-if="loanReviewDetail.state === '-1'" style="color: blue">审核中</span>
        </span>
      </Divider>
      <el-table :data="List" border style="width: 100%">
        <el-table-column prop="name" label="审批人">
          <template slot-scope="scope">
            <span>{{ scope.row.checkerName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkerPhone" label="手机号">
          <template slot-scope="scope">
            <span>{{ scope.row.checkerPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state === '-1'" style="color: blue">待审核</span>
            <span v-if="scope.row.state === '0'" style="color: red">未通过</span>
            <span v-if="scope.row.state === '1'" style="color: blue">通过</span>
            <span v-if="scope.row.state === '2'" style="color: red">作废</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkTime" label="时间">
          <template slot-scope="scope">
            <span>{{ scope.row.checkTime | dateVal }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="goPayDetail()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const projectCheckSms = api()('project.check.sms.json');
export const projectCheckInfo = api()('project.check.info.json');
export const projectCheckReview = api()('project.check.review.json');
export default {
  data () {
    return {
      phone: '',
      sms: '',
      isSend: false,
      seconds: 60,
      remark: '',
      loanReviewDetail: {},
      List: [],
    }
  },
  props: {
    code: String,
    payShow: Boolean,
    Item: Object,
    title: String
  },
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  methods: {
    close () {
      this.$emit('update:payShow', false);
      this.$emit('close');
    },
    loading () {
      const params = {
        code: this.code
      };
      projectCheckInfo(params).then((res) => {
        if (this.title === '借款复核') {
          this.List = res.list.filter((it) => it.type === 'borrowViewers');
        }
        else if (this.title === '还款复核') {
          this.List = res.list.filter((it) => it.type === 'payBackViewers');
        }
        else {
          this.List = res.list.filter((it) => it.type === 'carryOverViewers');
        }
      });
      this.loanReviewDetail = this.Item;
      this.phone = window.localStorage.getItem('phone');

    },
    async sendSmsCode () {
      this.isSend = true;
      await projectCheckSms({ code: this.code });
      const time = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          clearInterval(time);
          this.seconds = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    goPayDetail () {
      const params = {
        code: this.code,
        smsCode: this.sms,
        reviewRemark: this.remark
      };
      projectCheckReview(params).then((res) => {
        // const message = res;
        console.log(res);
        this.$message({
          type: 'success',
          message: '复核完成',
        });
        this.sms = '';
        this.close();
      });
    },
  },
}
</script>